const ProfilePicture = () => import(/* webpackChunkName: "profilepicture" */'./views/ProfilePicture.vue');
const ProfileCard = () => import(/* webpackChunkName: "profilecard" */'./views/ProfileCard.vue');
const ProfileData = () => import(/* webpackChunkName: "profiledata" */'./views/ProfileData.vue');
const ProfileChangePassword = () => import(/* webpackChunkName: "profilechangepassword" */'./views/ProfileChangePassword.vue');

const PreferencesSliders = () => import(/* webpackChunkName: "preferencessliders" */'./views/PreferencesSliders.vue');
const PreferencesNotifications = () => import(/* webpackChunkName: "preferencesnotifications" */'./views/PreferencesNotifications.vue');

const ProfilePersonalData = () => import(/* webpackChunkName: "profilepersonaldata" */'./views/ProfilePersonalData.vue');

export default [
  {
    path: '/profile',
    name: 'profile',
    redirect: '/profile/data',
  },
  {
    path: '/profile/photo',
    name: 'profilephoto',
    component: ProfilePicture,
    meta: {
      requiresAuth: true,
      group: 'user',
      application: 'profile',
    },
  },
  {
    path: '/profile/card',
    name: 'profilecard',
    component: ProfileCard,
    meta: {
      requiresAuth: true,
      group: 'user',
      application: 'profile-card',
    },
  },
  {
    path: '/profile/data',
    name: 'profiledata',
    component: ProfileData,
    meta: {
      requiresAuth: true,
      group: 'user',
      application: 'profile-data',
    },
  },
  {
    path: '/profile/personal-data',
    name: 'profilepersonaldata',
    component: ProfilePersonalData,
    meta: {
      requiresAuth: true,
      group: 'user',
      application: 'profile-personal-data',
      hiddenFeature: true,
    },
  },
  {
    path: '/profile/password',
    name: 'profilechangepassword',
    component: ProfileChangePassword,
    meta: {
      requiresAuth: true,
      group: 'user',
      application: 'profile-change-password',
    },
  },
  {
    path: '/preferences',
    name: 'preferences',
    component: PreferencesSliders,
    meta: {
      requiresAuth: true,
      group: 'user',
      application: 'preferences',
    },
  },
  {
    path: '/preferences/notifications',
    name: 'preferencesNotifications',
    component: PreferencesNotifications,
    meta: {
      requiresAuth: true,
      group: 'user',
      application: 'preferences-notifications',
    },
  },
];
