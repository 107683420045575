const ConsultaMios = () => import(/* webpackChunkName: "MuestraCertificados" */'./views/ConsultaMios.vue');
const SolicitudTodos = () => import(/* webpackChunkName: "SolicitaCertificados" */'./views/SolicitudTodos.vue');
const HaceConstarGrupoRoutes = () => import(/* webpackChunkName: "gestecon-haceConstarGrupo" */'./views/HaceConstarGrupo.vue');
const HaceConstarProdRoutes = () => import(/* webpackChunkName: "gestecon-haceConstarProd" */'./views/HaceConstarProd.vue');

export default [
  {
    path: '/mis-certificados/',
    name: 'ConsultaMios',
    component: ConsultaMios,
    alias: '/mis-certificados/filter/:descripcion',
    meta: {
      main: true,
      requiresAuth: true,
      group: 'campus-virtual',
      application: 'mis-certificados',
    },
  },
  {
    path: '/mis-certificados/listado-disponibles',
    name: 'SolicitudTodos',
    component: SolicitudTodos,
    alias: '/mis-certificados/listado-disponibles/filter/:descripcion',
    meta: {
      requiresAuth: true,
      group: 'campus-virtual',
      application: 'mis-certificados',
    },
  },
  {
    path: '/mis-certificados/hace-constar-grupo/',
    name: 'HaceConstarGrupo',
    component: HaceConstarGrupoRoutes,
    meta: {
      hiddenFeature: true,
      requiresAuth: true,
      group: 'gestecon',
      application: 'mis-certificados',
    },
  },
  {
    path: '/mis-certificados/hace-constar-prod-cientifica/:emite?',
    name: 'HaceConstarProd',
    component: HaceConstarProdRoutes,
    meta: {
      hiddenFeature: true,
      requiresAuth: true,
      group: 'gestecon',
      application: 'mis-certificados',
    },
  },
];
